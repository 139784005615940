import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Coming Soon :) 
        </h1>
        <h2>
          Please Stand By
        </h2>

      </header>
    </div>
  );
}

export default App;
